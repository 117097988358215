import React from 'react';
import './emptySpace.css';

const CLASS_NAME = 'empty-space';
class EmptySpace extends React.Component {
    render() {
        const { height, noMobile = false } = this.props;
        let className = CLASS_NAME;
        const style = {
            height: `${height}px`,
        };

        if (noMobile) {
            className += ` ${CLASS_NAME}__no-mobile`;
        }

        return <div className={className} style={style} />;
    }
}

export default EmptySpace;
