import React from 'react';

import './pageTitleLine.css';

const CLASS_NAME = 'page-title-line';

class PageTitleLine extends React.Component {
    render() {
        return (
            <div className={CLASS_NAME} id={this.props.idTag}>
                <h2 className={`${CLASS_NAME}__title`}>{this.props.title}</h2>
            </div>
        );
    }
}

export default PageTitleLine;
