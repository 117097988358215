import React from 'react';
import './EmptySpaceBigScreen.css';

const CLASS_NAME = 'empty-space-big-screen';
class EmptySpaceBigScreen extends React.Component {
    render() {
        const { height } = this.props;
        let className = CLASS_NAME;
        const style = {
            height: `${height}px`,
        };

        return <div className={className} style={style} />;
    }
}

export default EmptySpaceBigScreen;
